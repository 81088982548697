import React from "react"
import styles from "./kurumsal.module.scss"
import Menubar from "../components/menubar/menubar"
import Year40 from "../img/40yil.svg"
import Footer from "../components/footer/footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import TopImage from "../img/about01.jpg"
import BottomImage from "../img/about02.jpg"
import Uretim1 from "../img/uretim1.jpg"
import Uretim2 from "../img/uretim2.jpg"
import Uretim3 from "../img/uretim3.jpg"
export default () => (
  <div className={styles.kurumsalContainer}>
    <div className={styles.leftE}></div>
    <div className={styles.centerSite}>
      <Menubar />
      <div className={styles.imageTopAboutContainer}>
        <img src={TopImage} alt="oyma" className={styles.imageTopAbout} />
        <div className={styles.verticalLineWhiteAbout}>
          <div className={styles.socialMediaIcons}>
            <a
              href="https://www.facebook.com/atiaahsap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size="1x"
                className={styles.ffIcon}
              />
            </a>
            <br />
            <a
              href="https://www.instagram.com/atiaahsap/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="1x"
                className={styles.igIcon}
              />
            </a>
          </div>
        </div>
        <span className={styles.topSpanAboutInt}>INTERIOR</span>
        <span className={styles.topSpanAbout}>
          ATIA <br />
          HAKKINDA
        </span>
        <span className={styles.topSpanAboutBottom}>
          ŞİRKET <span style={{ color: "#977851" }}>PROFİLİ</span>
        </span>
      </div>
      <div className={styles.soylem}>
        <p>
          1997’de Haluk İDER tarafından, 180 m2lik bir imalat atölyesi olarak
          kurulan Atia, 2009 yılında 600 m2 lik bir alana geçtikten sonra, 2014
          yılında; içerisinde ahşap atölyesi, cila atölyesi, proje ofisi ve CNC
          işlem merkezi barındıran 2500 m2lik bir alana sahip olmuştur.
        </p>
        <p>
          Atia mekânı doğru biçimde kullanan, karakterli ve ergonomik yaşam
          alanlarında bulunmak isteyen herkesin, yaşam kalitesini ve konforunu
          öne çıkartan mekanlar sunar. Projelerinde uygulama kalitesiyle ve
          detaylara getirdiği yenilikçi ve farklı çözümlemeleriyle öne çıkan
          ATİA AHŞAP, bünyesinde barındırdığı iç mimarlar ve teknik ekiple iç
          mimarlık, proje, uygulama ve danışmanlık hizmeti de vermektedir.
        </p>
      </div>
      <div className={styles.uretimContainer}>
        <span className={styles.uretimTitle}>ÜRETİM</span>
        <div className={styles.uretimLines}>
          <div className={styles.eu1} />
          <div className={styles.uu1}>
            <img src={Uretim1} alt="uretim1" />
            <p>KARAKTERLİ</p>
            <div className={styles.thinBottomLineAbout} />
          </div>
          <div className={styles.uu2}>
            <img src={Uretim2} alt="uretim2" />
            <p>ERGONOMİK</p>
            <div className={styles.thinBottomLineAbout} />
          </div>
          <div className={styles.uu3}>
            <img src={Uretim3} alt="uretim3" />
            <p>KALİTELİ</p>
            <div className={styles.thinBottomLineAbout} />
          </div>
          <div className={styles.eu2} />
        </div>
      </div>
      <div className={styles.soylem}>
        <p>
          Atia, yaşam alanlarını yeniden tanımlarken; kullanılan malzemelerin
          uluslararası standartlara uygun, geri dönüştürülebilen ve çevre dostu
          olmasına dikkat etmektedir. Bu hassasiyeti hem doğaya hem de geleceğe
          yatırım amacıyla diktiği fidanlarla sürdürürken, sosyal sorumluluğunu
          da yerine getirmektedir
        </p>
      </div>
      <div className={styles.bottomContainer}>
        <img src={BottomImage} alt="door" className={styles.bottomImageAbout} />
        <div className={styles.bottomContainerTextContainer}>
          <p className={styles.bottomContainerText}>
            Arkasında 36 yıllık bir deneyim ve birikim bulunduran ATİA markası,
            üretime işin temelinden başlayıp, bugünkü kalite çizgisine
            ulaşmıştır. Geride kalan 40 yıla yakın zamanda, müşteriler ile
            kurulan ikili iletişimin ve samimiyetin gerektirdiği sorumluluğu
            layığıyla üstlenmiş ve günümüze kadar taşımıştır.
          </p>
        </div>
      </div>
      <div className={styles.year36Container}>
        <img src={Year40} alt="" />
      </div>
      <Footer />
    </div>
    <div className={styles.rightE}></div>
  </div>
)
